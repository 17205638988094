.helloView {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 150px;
}

.helloItem {
    text-align: center;
    width: 48%;
    margin: 10px;
}

.helloItem img {
    width: 100%;
}

@media (width <= 530px) {
    .helloItem {
        margin: 20px;
        width: 100%;
    }
}

@media (width <= 750px) {
    .helloItem {
        width: 70%;
    }
}

hr {
    border: none;
    border: solid #d6d6d6 0.5px;
    color: #e9e9e9;
}

.helloMenuBox h2 {
    text-align: center;
    padding-bottom: 40px;
}

.helloMenuView {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 150px;
}
.helloMenuItem {
    width: 40%;
    text-align: center;
}
.helloMenuItem img {
    width: 120px;
}

@media (width <= 530px) {
    .helloMenuItem h2 {
        font-size: 20px;
    }
    .helloMenuItem p {
        font-size: 13px;
    }
}

@media (width <= 750px) {
    .helloItem {
        width: 70%;
    }
}

span {
    display: inline-block;
}