.sticky {
    width: 300px;
    height: 300px;
    background: #f2be3a;
    border-radius: 0 0 10px 0;
    box-shadow: #8a8a8ab9 0px 1px 2px 0.3px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    word-break: break-all;
}