@import url('https://fonts.googleapis.com/css2?family=Yomogi&display=swap');

body {
  margin: 0;
  background: #f5f5f4;
  font-family: "Yomogi", cursive;
  font-weight: 400;
  font-style: normal;
  color: #37311f;
}

* :focus {
  outline: none;
}

/* viewContainer System */
.viewContainer {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 800px) {
  .viewContainer {
      width: 750px;
  }
}

@media (min-width: 1020px) {
  .viewContainer {
      width: 970px;
  }
}

@media (min-width: 1220px) {
  .viewContainer {
      width: 1170px;
  }
}